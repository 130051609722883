@tailwind base;

@layer base {
  h1 {
    @apply text-2xl;
    @apply font-bold;
  }
  h2 {
    @apply text-xl;
    @apply font-bold;
  }
  h3 {
    @apply text-lg;
    @apply font-bold;
  }
  a {
    @apply text-sky-blue underline;
  }
}

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;900&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

/* angled div */
.slanted-div {
  position: relative;
  padding: 200px 0;
  overflow: visible;
  z-index: 1;
}

/* where the magic happens */
.slanted-div:before, .slanted-div:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: right top;
  transform: skewY(3deg);
}

.slanted-div:after {
  bottom: 0;
  /* transform-origin: left bottom;
  transform: skewY(3deg); */
}

/* displays the content inside, as these settings in the parent breaks the effect */
/* .slanted-div div {
  text-align: center;
  font-size: 1.5em;
  line-height: 1.5;
} */